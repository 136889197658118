import { index, bindingCallHistory, totalStatistics, trunckDiagrams, userDiagrams } from "@/api/contacts/callHistories";

export const actions = {
    index({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            index(params)
                .then(res => {
                    commit("SET_LIST", res.data.data);
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        }); 
    },
    index_new({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            index(params)
                .then(res => {
                    commit("SET_LIST", res.data.data);
                    commit("UPDATE_PAGINATION", { key: 'total', value: res.data.total });
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        }); 
    },

    totalStatistics({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            totalStatistics(params)
                .then(res => {
                    commit("SET_TOTAL_STATISTICS", res.data.data);
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        }); 
    },

    trunckDiagrams({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            trunckDiagrams(params)
                .then(res => {
                    commit("SET_TRUNCK_DIAGRAMS", res.data.data);
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        }); 
    },

    userDiagrams({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            userDiagrams(params)
                .then(res => {
                    commit("SET_USER_DIAGRAMS", res.data.data);
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        }); 
    },

    bindingCallHistory({ commit }, data) {
        return new Promise((resolve, reject) => {
            bindingCallHistory(data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        }); 
    },
    getPhoneCalls({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            index(params)
                .then(res => {
                    commit("SET_PHONE_CALL_HISTORY", res.data.data);
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        }); 
    },

    updatePagination({ commit }, pagination) {
        commit('UPDATE_PAGINATION', pagination)
    },

    updateFilter({ commit }, filter) {
        commit('SET_FILTER', JSON.parse(JSON.stringify(filter)))
    },

    updateColumn({ commit }, obj) {
        commit('UPDATE_COLUMN', obj)
    },

    refreshData({ commit }) {
        return new Promise((resolve, reject) => {
            commit("REFRESH");
            resolve()
        })
    },

    emptyList({ commit }) {
        return new Promise((resolve, reject) => {
            commit("SET_LIST", []);
            resolve()
        })
    },

};
